import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RestApiService } from '../service/restapi.service';
import {
  GET_INVITATION_BY_ID_URL,
  ADD_INVITATION_URL,
  EDIT_INVITATION_URL,
} from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { UIUtil } from '../util/ui-util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DateTimeUtil } from '../util/date-time-util';
import { EmailInputComponent } from '../email-input/email-input.component';
import { FormControl } from '@angular/forms';
import { NgxMatDateAdapter, NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { CustomNgxDatetimeAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '../common/datetime-picker/CustomNgxDatetimeAdapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    //dateInput: 'YYYY-MM-DD HH:mm:ss',
    dateInput: 'DD-MM-YYYY hh:mm:ss A',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-tutor-invitation-mgt-dialog',
  templateUrl: './tutor-invitation-mgt-dialog.component.html',
  styleUrls: ['./tutor-invitation-mgt-dialog.component.css'],
  providers: [
    {
      provide: NgxMatDateAdapter,
      useClass: CustomNgxDatetimeAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }]
})
export class TutorInvitationMgtDialogComponent implements OnInit {
  @ViewChild('emailInputComponent') emailInputComponent: EmailInputComponent;

  public lessonStartDateCtrl:FormControl = new FormControl(new Date());

  public errorMessageMap = new Map<string, string>();
  public title: string;
  public inputMode: string;
  public selectedInvitationId: string;
  public lessonInvitation: any = {
    lesson: {},
    studentAttendeeEmails: [],
  };
  public studentEmailIds: string;

  constructor(
    public bsModalRef: BsModalRef,
    private restApi: RestApiService,
    private uiUtil: UIUtil,
    private dateTimeUtil: DateTimeUtil
  ) {}

  ngOnInit(): void {
    if (this.inputMode == 'EDIT') {
      this.loadInvitation();
    }
    this.title = (this.inputMode == 'CREATE' ? 'Add' : 'Edit') + ' Invitation';
  }

  loadInvitation() {
    this.restApi.getItem(
      GET_INVITATION_BY_ID_URL + this.selectedInvitationId,
      (response: ApiResponse) => {
        this.lessonInvitation = response.responseObject;
        this.lessonStartDateCtrl = new FormControl(new Date(this.lessonInvitation.lesson.startTime));
        /*
        this.lessonInvitation.lesson.startTime =
          this.dateTimeUtil.formatToUIDateTime(
            this.lessonInvitation.lesson.startTime
          );
            */
        this.emailInputComponent.emails =
          this.uiUtil.stringArrayToTagCompDataArray(
            this.lessonInvitation.studentAttendeeEmails
          );
      }
    );
  }

  handleSubmitButtonClick() {
    
    this.lessonInvitation.lesson.startTime = this.lessonStartDateCtrl.value;
      /*this.dateTimeUtil.formatToServerDateTime(
        this.lessonInvitation.lesson.startTime
      );*/

    this.lessonInvitation.studentAttendeeEmails =
      this.uiUtil.tagCompDataArrayToStringArray(
        this.emailInputComponent.emails
      );

    var invitationRequest = {
      inputMode: this.inputMode,
      lessonInvitation: this.lessonInvitation,
    };

    this.restApi.serverApiCall(
      this.inputMode == 'CREATE' ? 'POST' : 'PUT',
      this.inputMode == 'CREATE' ? ADD_INVITATION_URL : EDIT_INVITATION_URL,
      JSON.stringify(invitationRequest),
      (response: ApiResponse) => {
        console.log(response);

        this.bsModalRef.content = {
          closeEvent: 'submitted',
        };
        this.bsModalRef.hide();
        this.uiUtil.showSuccessMessage(
          'Invitation has been ' +
            (this.inputMode == 'CREATE' ? 'added' : 'updated') +
            ' successfully !'
        );
      },
      (error: ApiResponse) => {
        /*
        this.lessonInvitation.lesson.startTime =
          this.dateTimeUtil.formatToUIDateTime(
            this.lessonInvitation.lesson.startTime
          );*/
        this.errorMessageMap = this.uiUtil.covertMsgListToMap(
          error.messageList
        );
        console.log(error);
      }
    );
  }
}
