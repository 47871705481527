import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestApiService } from '../service/restapi.service';
import { START_LESSON_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.css'],
})
export class LessonComponent implements OnInit {
  public lessonInvitationId: string;
  public api: any;
  public lessonStatus: string;
  public lessonStartTime: number;
  public lessonEndTime: number;
  public lessonTitle: string;
  public hideVirtualBackgroundBtn: Boolean;
  public jitsiParam: any = {
    userInfo: {},
  };

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;
  isPeriodicTable = false;
  isCalculator = false;
  isDictionary = false;
  isMetronome = false;
  public selectedBPM = "150";
  public volume: number;
  metronomeSoundInterval = null;
  isPlaying: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private restApi: RestApiService
  ) { }

  ngOnInit(): void {
    this.checkisSafari();
    this.route.queryParams.subscribe((params) => {
      this.lessonInvitationId = params.lessonInvitationId;
    });
    this.getLessonStatus();
  }

  initVideo(): void {
    const options = {
      roomName: this.jitsiParam.roomName,
      width: '100%',
      height: '100%',
      parentNode: document.querySelector('#videoDiv'),
      jwt: this.jitsiParam.jwt,
      userInfo: this.jitsiParam.userInfo,
    };

    console.log('optionssa re');
    console.log(options);
    this.api = new JitsiMeetExternalAPI(this.jitsiParam.domain, options);

    this.api.executeCommand('subject', this.lessonTitle);
    this.api.executeCommand('overwriteConfig', {
      toolbarButtons: [],
    });
  }

  toggleCamera(): void {
    var currDevice = this.api.getCurrentDevices();
    var allDevices = this.api.getAvailableDevices();
    var currDeviceId = null;

    currDevice.then((data: any) => {
      var videoDevices = data.videoInput;
      currDeviceId = videoDevices.deviceId;

      allDevices.then((data: any) => {
        var videoDevices = data.videoInput;
        for (let i = 0; i < videoDevices.length; i++) {
          if (currDeviceId != videoDevices[i].deviceId) {
            this.api.setVideoInputDevice(
              videoDevices[i].label,
              videoDevices[i].deviceId
            );
          }
        }
      });
    });
  }

  endCall(): void {
    this.executeCommand('hangup');
    window.parent.close();
  }

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  executeCommand(command: string): void {
    this.api.executeCommand(command);
    if (command == 'hangup') {
      window.parent.close();
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }

  getLessonStatus(): void {
    this.restApi.postItem(
      START_LESSON_URL,
      JSON.stringify({ lessonInvitationId: this.lessonInvitationId }),
      (response: ApiResponse) => {
        var responseObj = response.responseObject;
        console.log(response.responseObject);
        this.lessonStatus = responseObj.lessonStatus;
        this.lessonStartTime = responseObj.lessonStartTime;
        this.lessonEndTime = responseObj.lessonEndTime;
        this.jitsiParam = responseObj.jitsiParams;
        this.lessonTitle = responseObj.lessonTitle;
        if (this.lessonStatus == 'STARTED') {
          console.log(this.jitsiParam);
          this.initVideo();
        }
      }
    );
  }

  timerFinished(): void {
    location.reload();
  }

  openWhieBoard(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/whiteboard'], {
        queryParams: { lessonInvitationId: this.lessonInvitationId },
      })
    );
    window.open(url, '_blank');
  }

  toggleVirtualBackground(): void {
    this.api.executeCommand('toggleVirtualBackgroundDialog');
  }

  checkisSafari(): void {
    let is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.hideVirtualBackgroundBtn = is_safari;
  }

  togglePeriodicTable(): void {
    this.isPeriodicTable = !this.isPeriodicTable;
  }

  toggleCalculator(): void {
    this.isCalculator = !this.isCalculator;
  }

  toggleDictionary(): void {
    this.isDictionary = !this.isDictionary;
  }

  toggleMetronome(): void {
    this.isMetronome = !this.isMetronome;
  }

  playMetronome(): void {
    this.isPlaying = !this.isPlaying;
    this.playMetronomeSound();
  }

  pauseMetronome(): void {
    this.isPlaying = !this.isPlaying;
    clearInterval(this.metronomeSoundInterval);
  }

  updateBpmDisplay(e: any): void {
    this.selectedBPM = e.target.value;
    this.playMetronomeSound();
  }

  changeValume(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.volume = +inputElement.value;
    var audioElem = document.getElementById("metroNomeAudioElem") as HTMLAudioElement;
    if (this.volume) {
      audioElem.volume = this.volume / 100;
    }
  }

  playMetronomeSound(): void {
    clearInterval(this.metronomeSoundInterval);
    if (this.isPlaying) {
      let bpm = this.selectedBPM as unknown as number;
      var audioElem = document.getElementById("metroNomeAudioElem") as HTMLAudioElement;
      this.metronomeSoundInterval = setInterval(function () { audioElem.play() }, (60 / bpm) * 1000);
    }
  }
}
