<h3>{{title}}</h3>
<div style="color:red">{{this.errorMessageMap.get('invitationMgtCommonError')}}</div>
<div class="row">
    <div class="col-md-12">
        <app-email-input #emailInputComponent></app-email-input>
        <div style="color:red">{{this.errorMessageMap.get('lessonInvitation.studentAttendeeEmails')}}</div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <input type="text" class="form-control form-control-lg" [(ngModel)]="lessonInvitation.lesson.title"
            placeholder="SESSION TITLE">
        <div style="color:red">{{this.errorMessageMap.get('lessonInvitation.lesson.title')}}</div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <textarea rows="5" class="form-control form-control-lg" [(ngModel)]="lessonInvitation.lesson.description"
            placeholder="Description"></textarea>
        <div style="color:red">{{this.errorMessageMap.get('lessonInvitation.lesson.description')}}</div>
    </div>
</div>
<!--
<div class="row">
    <div class="col-md-12">
        <input type="datetime-local" class="form-control form-control-lg" 
        [(ngModel)]="lessonInvitation.lesson.startTime"
        (ngModelChange)="lessonInvitation.lesson.startTime = $event"
            placeholder="SESSION START TIME" >
            <div style="color:red">{{this.errorMessageMap.get('lessonInvitation.lesson.startTime')}}</div>
    </div>
</div>
-->


<div class="row">
    <div class="col-md-12">
        <mat-form-field>
            <input matInput [ngxMatDatetimePicker]="picker" placeholder="SESSION START TIME" readonly
                [formControl]="lessonStartDateCtrl">
            <div style="color:red">{{this.errorMessageMap.get('lessonInvitation.lesson.startTime')}}</div>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSeconds]="false" [enableMeridian]="true" >
                <ng-template>
                    <span>OK</span>
                </ng-template>
            </ngx-mat-datetime-picker>
        </mat-form-field>
    </div>
    
</div>


<div class="form-group">
    <div class="form-check">
        <button type="button" class="btn btn-primary btn-lg next-step pull-right" (click)="handleSubmitButtonClick()"
            style="background: #fe7f38; color: #fff;border: none;margin-bottom: 15px;">SUBMIT</button>
    </div>
</div>