import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WHITEBOARD_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { RestApiService } from '../service/restapi.service';

declare var api;

@Component({
  selector: 'app-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.css'],
})
export class WhiteboardComponent implements OnInit {
  public lessonInvitationId: string;
  public whiteBoardClientId: string;
  public lessonStatus: string;
  public whiteBoardId: string;

  constructor(private route: ActivatedRoute, private restApi: RestApiService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      this.lessonInvitationId = params.lessonInvitationId;
    });
    this.getLessonStatus();
  }

  getLessonStatus(): void {
    this.restApi.postItem(
      WHITEBOARD_URL,
      JSON.stringify({ lessonInvitationId: this.lessonInvitationId }),
      (response: ApiResponse) => {
        var responseObj = response.responseObject;
        console.log(response.responseObject);
        this.lessonStatus = responseObj.lessonStatus;
        this.whiteBoardClientId = responseObj.whiteBoardClientId;
        this.whiteBoardId = responseObj.whiteBoardId;
        if (this.lessonStatus == 'STARTED') {
          this.initWhiteboard();
        }
      }
    );
  }

  initWhiteboard() {
    var wt = new api.WhiteboardTeam('#wt-container', {
      clientId: this.whiteBoardClientId,
      boardCode: this.whiteBoardId,
    });
  }
}
