import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractDataGridSupport } from '../base/AbstractDataGridSupport';
import { GET_INVITATIONS_LIST_STUDENT_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { DataGridParams } from '../model/datagrid-model';
import { RestApiService } from '../service/restapi.service';

@Component({
  selector: 'app-student-view-invitations',
  templateUrl: './student-view-invitations.component.html',
  styleUrls: ['./student-view-invitations.component.css']
})
export class StudentViewInvitationsComponent extends AbstractDataGridSupport implements OnInit {

  public invitations: any = [];

  constructor(
    private restApi: RestApiService,
    private router: Router
  ) {
    super();
  }

  getIDataGridParams(): DataGridParams {
    let dataGridParams: DataGridParams = {
      displayedColumns: [
        'lessInvitation.lesson.title',
        'lessInvitation.lesson.startTime',
        'lessInvitation.lesson.lessonStatus',
        'actionButtonColumn'
      ],
      serverApi: () => {
        this.loadInvitationlist();
      },
    };
    return dataGridParams;
  }

  loadInvitationlist(): void {
    this.restApi.postItem(
      GET_INVITATIONS_LIST_STUDENT_URL,
      JSON.stringify(this.getDataGridRequest()),
      (response: ApiResponse) => {
        this.assignDataSourceParams(response.responseObject);
      }
    );
  }

  ngOnInit(): void {
    //this.initializeUIObjects();
  }

  initializeUIObjects() {
    this.loadInvitationlist();
  }

  openSessionPage(invitationId: number){
    this.router.navigate([]).then(result => {  window.open('/lesson?lessonInvitationId='+invitationId, '_blank'); });
  }
}
