import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-lesson-dictionary',
  standalone: true,
  imports: [CommonModule, MatIconModule, FormsModule,BrowserModule],
  templateUrl: './lesson-dictionary.component.html',
  styleUrl: './lesson-dictionary.component.css'
})
export class LessonDictionaryComponent implements OnInit {

  public searchResultView: boolean = false;
  public searchResult: any = {};
  public searchWord: any;
  public selectedLanguage: any = "en";
  public verbs: any = [];
  public pronunciation: any = [];
  public definition: any = [];
  public example: any;
  public verb: any = [];
  public noun: any = [];
  public adjective: any = [];
  public hasError: boolean= false;

  readonly APIUrl = "https://dictionary-api.meteortutors.com/api/dictionary/"
  public volume: number | undefined;

  constructor(
    private elementRef: ElementRef,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.ownerDocument
    .body.style.backgroundColor = '#eb5c34';
    window.addEventListener('clearSearch', () => {
      this.clearSearch();
    });
  }

  searchEvent() {
    this.verb = [];
    this.noun = [];
    this.adjective = [];
    this.http.get(this.APIUrl + this.selectedLanguage + "/" + this.searchWord).subscribe((data: any) => {
      this.hasError = false;
      this.searchResult = data;
      console.log(this.searchResult);
      if (this.searchResult != null) {
        this.searchResultView = true;
      } else {
        this.searchResultView = false;
      }
      this.verbs = data.verbs;
      this.pronunciation = data.pronunciation;
      this.definition = data.definition;

      this.example = this.definition[0].example;

      for(let value of this.pronunciation){
        if(value.pos == "noun"){
          this.noun.push(value);
        } else if(value.pos == "verb"){
          this.verb.push(value);
        } else if(value.pos == "adjective"){
          this.adjective.push(value);
        }
      }
    },
    error => {
      this.hasError = true;
    });
  }

  public clearSearch(): void {
    this.searchResultView = false;
    this.searchWord = "";
  }

}
