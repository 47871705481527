import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_CODE_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { LoginUserInfo } from '../model/LoginUserInfo';
import { RestApiService } from '../service/restapi.service';
import { UIUtil } from '../util/ui-util';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login-app.component.html',
  styleUrls: ['./login-app.component.css'],
})
export class LoginAppComponent implements OnInit {
  
public loginAppCode: any = {};

constructor(
  private route: ActivatedRoute,
  private router: Router,
  private restApi: RestApiService,
  private uiUtil: UIUtil
) {}

ngOnInit(): void {
  localStorage.removeItem('loginUserInfo');
  const code = this.route.snapshot.queryParamMap.get('code');
  this.loginAppCode = {"wondeUserCode":code};
  this.restApi.postItem(
    LOGIN_CODE_URL,
    JSON.stringify(this.loginAppCode),
    (response: ApiResponse) => {
      var loginUserInfo: LoginUserInfo = response.responseObject;
      localStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
      this.redirectToHomePage(loginUserInfo.role);
    },
    (error: ApiResponse) => {
      this.router.navigate(['login']).then(() => {
        window.location.reload();
      });
      this.uiUtil.showErrorMessage(error.messageList[0].message);
    }
  );
}

redirectToHomePage(role: string): void {
  if (role == 'STUDENT') {
    this.router.navigate(['student-page']).then(() => {
      window.location.reload();
    });
  } else if (role == 'TUTOR') {
    this.router.navigate(['tutor-page']).then(() => {
      window.location.reload();
    });
  } else if (role == 'ADMIN') {
    this.router.navigateByUrl('admin-page');
  }
}
}
