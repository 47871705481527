import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../service/restapi.service';
import {
  EMAIL_VERIFICATION_URL
} from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

  public verificationCode:string;
  public message:string;

  constructor(
    private restApi: RestApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }
        this.verificationCode = params.token
      }
    );

    this.checkVerification();
    
  }

  checkVerification(){
    this.restApi.getItem(
      EMAIL_VERIFICATION_URL+this.verificationCode ,
      (response: ApiResponse) => {
        console.log(response);
        this.message = response.messageList[0].message;
      }
    );
  }

}
