<div class="d-flex justify-content-center">
    <div class="search-box">
        <h1 style="text-align: left;">METEOR DICTIONARY</h1>
        <h5 style="text-align: left;">Powered by Cambridge Dictionary</h5>
        <div *ngIf="!searchResultView || hasError">
            <div class="main-page-search-box">
                <input type="text" class="text-box" placeholder="Search..." (keyup.enter)="searchEvent()"
                [(ngModel)]="searchWord">
                <mat-icon role="button" class="main-page-search-logo" aria-hidden="true" (click)="searchEvent()">search</mat-icon>
            </div>
            <div class="alert alert-danger position-absolute mt-2" role="alert" *ngIf="hasError">
                No Match!
            </div>
            <div class="logo-image">
                <img src="../../assets/images/logo/dictionary-logo.png">
            </div>
        </div>

        <div class="result-box" *ngIf="searchResultView && !hasError">
            <div class="result-view-text-and-dropdown" style="padding-left: 2px;">
                <input type="text" class="result-view-text" (keyup.enter)="searchEvent()" [(ngModel)]="searchWord"
                    placeholder="Search...">
                <mat-icon role="button" class="logo-image" aria-hidden="true" (click)="searchEvent()" style="float: left; padding: 4px; width: 36px;">search</mat-icon>
                <!-- <select class="result-view-text-dropdown classic" [(ngModel)]="selectedLanguage" title="language">
                        <option value="en" selected>english (us)</option>
                        <option value="uk">english (uk)</option>
                        <option value="en-tw">english-chinese-traditional</option>
                        <option value="en-cn">english-chinese-simplified</option>
                    </select> -->
            </div>
            <div *ngIf="verbs != null">
                <div style="text-align: left; margin-top: 20px; font-weight: 600;">Verbs</div>
                <table>
                    <thead style="background-color: #e1e1e1; font-weight: 600;">
                        <tr>
                            <td>Type</td>
                            <td>Text</td>
                        </tr>
                    </thead>
                    <tbody *ngFor="let value of verbs">
                        <tr *ngIf="value != null">
                            <td>{{value.type}}</td>
                            <td>{{value.text}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="text-align: left; margin-top: 20px; font-weight: 600;">Pronounce</div>
            <div *ngFor="let value of searchResult.pos">
                <div *ngIf="value == 'verb'">
                    <div style="text-align: left; margin-bottom: 20px; font-weight: 600;">Verb</div>
                    <table>
                        <thead style="background-color: #e1e1e1; font-weight: 600;">
                            <tr>
                                <td>Lang</td>
                                <td>Pron</td>
                                <td>Play</td>
                            </tr>
                        </thead>
                        <tbody *ngFor="let value of verb; let i = index">
                            <tr *ngIf="value != null && i < 2">
                                <td>{{value.lang}}</td>
                                <td>{{value.pron}}</td>
                                <td>
                                    <audio controls>
                                        <source src="{{value.url}}" type="audio/mpeg">
                                    </audio>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngFor="let value of searchResult.pos">
                <div *ngIf="value == 'noun'">
                    <div style="text-align: left; margin-top: 20px; margin-bottom: 20px; font-weight: 600;">Noun
                    </div>
                    <table>
                        <thead style="background-color: #e1e1e1; font-weight: 600;">
                            <tr>
                                <td>Lang</td>
                                <td>Pron</td>
                                <td>Play</td>
                            </tr>
                        </thead>
                        <tbody *ngFor="let value of noun let i = index">
                            <tr *ngIf="value != null && i < 2">
                                <td>{{value.lang}}</td>
                                <td>{{value.pron}}</td>
                                <td>
                                    <audio controls>
                                        <source src="{{value.url}}" type="audio/mpeg">
                                    </audio>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngFor="let value of searchResult.pos">
                <div *ngIf="value =='adjective'">
                    <div style="text-align: left; margin-top: 20px; margin-bottom: 20px; font-weight: 600;">
                        Adjective </div>
                    <table>
                        <thead style="background-color: #e1e1e1; font-weight: 600;">
                            <tr>
                                <td>Lang</td>
                                <td>Pron</td>
                                <td>Play</td>
                            </tr>
                        </thead>
                        <tbody *ngFor="let value of adjective let i = index">
                            <tr *ngIf="value != null && i < 2">
                                <td>{{value.lang}}</td>
                                <td>{{value.pron}}</td>
                                <td>
                                    <audio controls>
                                        <source src="{{value.url}}" type="audio/mpeg">
                                    </audio>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style="text-align: left; margin-top: 20px; margin-bottom: 20px; font-weight: 600;">Definition</div>
            <div style="text-align: left; margin-top: 20px; margin-bottom: 20px; font-weight: 600;">Verb</div>
            <div *ngFor="let value of definition; let i = index">
                <div *ngIf="value != null && i == 0">
                    <p style="text-align: left;"><span style="font-style: italic;">text: </span> {{value.text}}</p>
                    <!-- <p style="text-align: left;">Translation:" {{value.translation}} "</p> -->
                </div>
            </div>

            <div>
                <div style="text-align: left; margin-top: 20px; font-weight: 600;">Example</div>
                <table>
                    <thead style="background-color: #e1e1e1; font-weight: 600;">
                        <tr>
                            <td>text</td>
                            <!-- <td>translation</td> -->
                        </tr>
                    </thead>
                    <tbody *ngFor="let value of example">
                        <tr *ngIf="value != null">
                            <td>{{value.text}}</td>
                            <!-- <td>{{value.translation}}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
