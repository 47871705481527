<header id="top" class="header fixed-top">
    <div class="">
        <nav id="main-nav" class="main-nav navbar-light navbar-expand-md" role="navigation" style="display: flex;">

            <div class="navbar-collapse collapse justify-content-start" id="navbar-collapse" [ngClass]="{ 'show': navbarOpen }"> 
                <!--//logo-->
                <ul class="nav navbar-nav" *ngIf="loginUserInfo == null">
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/student-registration']">STUDENTS
                            <i style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/tutor-registration']">TUTORS <i
                                style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/tutor-registration']">REGISTER <i
                                style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item last"><a class="nav-link scrollto" [routerLink]="['/login']">LOGIN <i
                                style="margin-left: 15px;"> |</i></a></li>
                    <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/contact-us']" style="margin-right: 15px;">CONTACT</a></li>
                </ul>
                <!--//nav-->
                <ul class="nav navbar-nav" *ngIf="loginUserInfo != null"> 
                    <li class="nav-item"><a class="nav-link scrollto" href="/">HOME <i
                            style="margin-left: 15px;"> |</i></a></li>

                    <li *ngFor="let p of appPages; let i = index" class="nav-item"><a class="nav-link scrollto"
                            [class.active]="currentPage == p.page" [routerLink]="[p.url]"
                            skipLocationChange>{{p.title}}<i style="margin-left: 15px;"> |</i></a></li>
                            <li class="nav-item"><a class="nav-link scrollto" [routerLink]="['/']" (click)="handleLogoutClick()">LOGOUT <i
                                style="margin-left: 15px;"> |</i></a></li>
                </ul>
            </div>

            <button class="navbar-toggler hidden-md hidden-lg" type="button" data-toggle="collapse" (click)="toggleNavbar()"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon" style="color: #35e3fc;"></span>
           </button>
            <h1 class="logo pull-right">
            <a class="scrollto" href="/">
                <img id="logo-image" class="logo-image" src="assets/images/logo/MeteorVideoOrangeCut.png" style="width:160px; margin-top: 20px; margin-right: 75px;" alt="Logo">
            </a>
            </h1>
            <!--//navabr-collapse-->
        </nav>
        <!--//main-nav-->

    </div>
</header>
<!--//header-->
