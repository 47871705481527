<tag-input [(ngModel)]='emails' 
#tagInput
[errorMessages]="errorMessages"
[validators]="validators" 
[editable]='true' 
[separatorKeyCodes]="[32,188,186,13,9]"
[placeholder]="'Add email'" 
[secondaryPlaceholder]="'Student email address list (comma seperated)'" 
[clearOnBlur]="true" 
[addOnPaste]="true"
[addOnBlur]="true"
[pasteSplitPattern]= "splitPattern"
[onAdding]="onAddedFunc"
theme='bootstrap' 
required >
</tag-input>