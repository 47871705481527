<app-header [currentPage]="'student-registration'"></app-header>

<section id="features" class=" section offset-header" style="background: #232323;">
    <div class="container" style=" padding-top: 20px;">
        <h3 class="title" ><b>Register For Meteor Video</b></h3>
           <h3 style="color:#999999"> <b>Before you start to Register, please read the following:</b><br>
            <small><b><span style="color: #fc7f38; font-size: 16px;">Important: </span>All you need to do is create a username (using alphanumerics only or 
                your valid email address) and password (must contain at least one number, one lower case, one upper case, one special character from [ &#64; # $ % ! ], 
                length between 8 and 32 characters). Once you have verified your email address you'll be able to connect with your guests or hosts.
            </b></small> </h3>
        <div class="row">
            <div class="item col-lg-12 col-md-12 col-12 mt-5" style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
                <div class="" style="padding: 20px 50px;">
                        
                            <div class="row">
                                <div class="col-md-6">
                                    <select class="form-control form-control-lg " (change)="changeUsertype($event.target.value)" >
                                        <option value="tutor">TUTOR</option>
                                        <option value="student" selected>STUDENT</option>
                                    </select>
                                    <div style="color:red;"></div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-control-lg" [(ngModel)]="studentRegRequest.studentData.accountInfo.emailId"
                                        placeholder="EMAIL ADDRESS" >
                                        <div style="color:red">{{this.errorMessageMap.get('studentData.accountInfo.emailId')}}</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-control-lg" [(ngModel)]="studentRegRequest.studentData.personalDetail.firstName"
                                        placeholder="FIRST NAME" >
                                        <div style="color:red">{{this.errorMessageMap.get('studentData.personalDetail.firstName')}}</div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-control-lg" [(ngModel)]="studentRegRequest.studentData.personalDetail.lastName"
                                        placeholder="LAST NAME" >
                                        <div style="color:red">{{this.errorMessageMap.get('studentData.personalDetail.lastName')}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="password" class="form-control form-control-lg" [(ngModel)]="studentRegRequest.studentData.accountInfo.passwordStr"
                                        placeholder="PASSWORD" >
                                        <div style="color:red">{{this.errorMessageMap.get('studentData.accountInfo.passwordStr')}}</div>
                                </div>
                                <div class="col-md-6">
                                    <input type="password" class="form-control form-control-lg" [(ngModel)]="studentRegRequest.studentData.accountInfo.confirmPasswordStr"
                                        placeholder="CONFIRM PASSWORD" >
                                        <div style="color:red">{{this.errorMessageMap.get('studentData.accountInfo.confirmPasswordStr')}}</div>
                                </div>
                            </div>

                           <div class="form-group">
                                <div class="form-check">
                                  
                                  <label class="form-check-label" for="gridCheck">
                                    I AGREE TO Meteor Video TERMS & CONDITIONS AND PRIVACY POLICY
                                  </label>
                                  <input class="form-check-input" 
                                  [(ngModel)]="studentRegRequest.isTermAgreed"
                                  style="position: relative;top: 3px;left: 5px;" type="checkbox" id="gridCheck">
                                  <div style="color:red">{{this.errorMessageMap.get('isTermAgreed')}}</div>
                                  <button type="button" class="btn btn-primary btn-lg next-step pull-right" style="background: #fe7f38; color: #fff;border: none;margin-bottom: 15px;"
                                  (click)="handleSubmitButtonClick()">SUBMIT</button>
                                </div>
                              </div>
                            <!-- <ul class="list-inline pull-right">
                                <li></li>
                            </ul> -->
                        
                    
                </div><!--//content-->               
            </div><!--//item-->
        </div><!--//row-->
        
        
        
    </div><!--//container-->
</section><!--//features-->





<app-footer></app-footer>


