import { Component, OnInit } from '@angular/core';
import { TUTOR_REGISTRATION_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { RestApiService } from '../service/restapi.service';
import { UIUtil } from '../util/ui-util';

@Component({
  selector: 'app-tutor-registration',
  templateUrl: './tutor-registration.component.html',
  styleUrls: ['./tutor-registration.component.css']
})
export class TutorRegistrationComponent implements OnInit {

  
  public tutorRegRequest: any;
  public errorMessageMap = new Map<string, string>();

  constructor(
    private restApi: RestApiService,
    private uiUtil: UIUtil
  ) {
    this.initializeTutorRegRequest();
  }

  private initializeTutorRegRequest(): void {
    this.tutorRegRequest = {
      tutorData: {
        personalDetail: {},
        accountInfo: {},
      },
    };
  }

  handleSubmitButtonClick(): void {
    this.errorMessageMap = new Map<string, string>();
    this.restApi.postItem(
      TUTOR_REGISTRATION_URL,
      JSON.stringify(this.tutorRegRequest),
      (response: ApiResponse) => {
        this.uiUtil.showSuccessMessage('Tutor registration success !');
        this.uiUtil.redirectTo('/registration-success');
      },
      (error: ApiResponse) => {
        this.errorMessageMap = this.uiUtil.covertMsgListToMap(
          error.messageList
        );
        console.log(error);
      }
    );
  }

  changeUsertype(selectedValue){
    if(selectedValue == 'student'){
      this.uiUtil.redirectTo('/student-registration');
    }
  }

  ngOnInit(): void {}

}
