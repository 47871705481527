//https://stackblitz.com/edit/ngx-chips-example-p8jsyz?file=app%2Fshared%2Ftag-input%2Ftag-input.component.ts
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { throwError, of, empty } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TagInputComponent as SourceTagInput } from 'ngx-chips';
import { UIUtil } from '../util/ui-util';

export interface AutoCompleteModel {
  value: any;
  display: string;
}

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.css'],
})
export class EmailInputComponent implements OnInit {
  @ViewChild('tagInput')
  tagInput: SourceTagInput;

  @Input() public emails: Array<any>;

  public splitPattern = new RegExp('[,;]');

  public validators = [this.must_be_email.bind(this)];
  public errorMessages = {
    must_be_email: 'Please be sure to use a valid email format',
  };

  public onAddedFunc = this.beforeAdd.bind(this);

  private addFirstAttemptFailed = false;

  private must_be_email(control: FormControl) {
    if (this.addFirstAttemptFailed && !this.validateEmail(control.value)) {
      return { must_be_email: true };
    }
    return null;
  }
  constructor(private uiUtil: UIUtil) {}

  ngOnInit() {}

  private beforeAdd(tagObj: any) {
    console.log('updated');
    console.log(this.emails);
    let tagVal: string = null;
    if (typeof tagObj === 'string') {
      tagVal = tagObj;
    } else if (tagObj.value != null) {
      console.log(tagObj);
      tagVal = tagObj.value;
    }

    if (!this.validateEmail(tagVal)) {
      if (!this.addFirstAttemptFailed) {
        this.addFirstAttemptFailed = true;
        this.tagInput.setInputValue(tagVal);
      }
      if (typeof tagObj === 'string') {
        return throwError(this.errorMessages['must_be_email']);
      } else {
        this.uiUtil.showErrorMessage('Invalid Email Id found');
      }
    }
    this.addFirstAttemptFailed = false;
    return of(tagVal);
  }

  private validateEmail(text: string) {
    var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i;
    return text && EMAIL_REGEXP.test(text);
  }
}
