<app-header [currentPage]="'login'"></app-header>
<section id="features" class=" section offset-header" style="background: #232323;">
    <div class="container" style=" padding-top: 20px;">
        <h3 class="title  "><b>Login</b></h3>
        <div class="row">
            <div class="item col-lg-12 col-md-12 col-12 mt-5"
                style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
                <div class="" style="padding: 20px 50px;">
                    <div class="row">
                        <div class="col-md-12">
                            <input type="email" [(ngModel)]="loginCredentialInput.emailId"
                                class="form-control form-control-lg" placeholder="EMAIL ID">
                            <div style="color:red"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <input type="password" [(ngModel)]="loginCredentialInput.password"
                                class="form-control form-control-lg" placeholder="PASSWORD">
                            <div style="color:red"></div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <button type="button" class="btn btn-primary btn-lg next-step pull-right"
                                (click)="handleLoginButtonClick()"
                                style="background: #fe7f38; color: #fff;border: none;margin-bottom: 15px;">LOGIN</button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <button type="button" class="btn btn-primary btn-lg next-step pull-right"
                                (click)="handleLoginAppButtonClick()"
                                style="background: #fe7f38; color: #fff;border: none;margin-bottom: 15px;">WONDE LOGIN</button> <br/>
                        </div>
                    </div>
                </div>
                <!--//content-->
            </div>
            <!--//item-->
        </div>
        <!--//row-->
    </div>
    <!--//container-->
</section>
<!--//features-->





<app-footer></app-footer>