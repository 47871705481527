import { Component, OnInit } from '@angular/core';
import { STUDENT_REGISTRATION_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { RestApiService } from '../service/restapi.service';
import { UIUtil } from '../util/ui-util';

@Component({
  selector: 'app-student-registration',
  templateUrl: './student-registration.component.html',
  styleUrls: ['./student-registration.component.css'],
})
export class StudentRegistrationComponent implements OnInit {
  public studentRegRequest: any;
  public errorMessageMap = new Map<string, string>();

  constructor(
    private restApi: RestApiService,
    private uiUtil: UIUtil,
  ) {
    this.initializeStudentRegRequest();
  }

  private initializeStudentRegRequest(): void {
    this.studentRegRequest = {
      studentData: {
        personalDetail: {},
        accountInfo: {},
      },
    };
  }

  handleSubmitButtonClick(): void {
    console.log(this.studentRegRequest);
    this.errorMessageMap = new Map<string, string>();
    this.restApi.postItem(
      STUDENT_REGISTRATION_URL,
      JSON.stringify(this.studentRegRequest),
      (response: ApiResponse) => {
        this.uiUtil.showSuccessMessage('Student registration success !');
        this.uiUtil.redirectTo('/registration-success');
        console.log(response);
      },
      (error: ApiResponse) => {
        this.errorMessageMap = this.uiUtil.covertMsgListToMap(
          error.messageList
        );
        console.log(error);
      }
    );
  }

  changeUsertype(selectedValue){
    if(selectedValue == 'tutor'){
      this.uiUtil.redirectTo('/tutor-registration');
    }
  }

  ngOnInit(): void {}
}
