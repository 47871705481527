import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LOGOUT_URL
} from '../../constants/ApiURLConst';
import { ApiResponse } from '../../model/apiresponse';
import { RestApiService } from '../../service/restapi.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  constructor(
    private restApi: RestApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  handleLogoutClick(): void {
    localStorage.removeItem('loginUserInfo');
    this.restApi.getItem(LOGOUT_URL, (response: ApiResponse) => {
      this.router.navigate(['login']);
      console.log('Logout success!');
    });
  }

}
