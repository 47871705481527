import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractDataGridSupport } from 'src/app/base/AbstractDataGridSupport';
import { ApiResponse } from 'src/app/model/apiresponse';
import { DataGridParams } from 'src/app/model/datagrid-model';
import { RestApiService } from 'src/app/service/restapi.service';
import {
  GET_USERS_URL,
  LOGOUT_URL
} from '../../constants/ApiURLConst';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent extends AbstractDataGridSupport implements OnInit {

  constructor(
    private restApi: RestApiService,
    private router: Router,
  ) {
    super();
    
   }

  ngOnInit(): void {
  }

  getIDataGridParams(): DataGridParams {
    let dataGridParams: DataGridParams = {
      displayedColumns: [
        'emailId',
        'role',
        'lastLogin',
        'accountStatus',
        'lastLoginTimeZone',
        'accountCreated'
      ],
      serverApi: () => {
        this.loadUserList();
      },
    };
    return dataGridParams;
  }

  loadUserList(){
    this.restApi.postItem(
      GET_USERS_URL,
      JSON.stringify(this.getDataGridRequest()),
      (response: ApiResponse) => {
        console.log(response);
        this.assignDataSourceParams(response.responseObject);
      }
    );
  }

  handleLogoutClick(): void {
    localStorage.removeItem('loginUserInfo');
    this.restApi.getItem(LOGOUT_URL, (response: ApiResponse) => {
      this.router.navigate(['login']);
      console.log('Logout success!');
    });
  }
}
