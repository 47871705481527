//export const DUMMY_SERVER_API_URL: string = 'http://localhost:8081';

//export const LOGINAPP_API_URL: string = "http://localhost:8080";

export const DUMMY_SERVER_API_URL: string ='https://api.meteor.video';

export const LOGINAPP_API_URL: string = 'https://app.mylogin.com/oauth/authorize?client_id=991d293f-62b7-47b5-acd2-a383a281c138&redirect_uri=https://meteor.video/callback&response_type=code';

export const STUDENT_REGISTRATION_URL: string = '/registration/student';
export const TUTOR_REGISTRATION_URL: string = '/registration/tutor';

export const LOGIN_URL: string = '/authentication/login';
export const LOGIN_CODE_URL: string = '/authentication/wonde/login';
export const LOGOUT_URL: string = '/authentication/wonde/logout';

export const CREATE_STRIPE_PAYMENT_INTENT_URL =
  '/payment/stripe/create-payment-intent';
export const CREATE_STRIPE_PAYMENT_CONFIRMATION_URL: string =
  '/payment/stripe/confirm-payment';

export const GET_AVAILABLE_TUTOR_SUBSCRIPTION_PLAN_URL: string =
  '/subscription/tutor/available-subscription-plans';
export const GET_TUTOR_SUBSCRIPTION_INFO_URL: string =
  '/subscription/tutor/subscription-info';
export const GET_TUTOR_SUBSCRIPTION_HISTORY_URL: string =
  '/subscription/tutor/subscription-history';
export const CREATE_INVITATION_URL: string =
  '/lesson-invitation/create-invitation';
export const GET_INVITATIONS_LIST_TUTOR_URL: string = 
   '/lesson-invitation/invitations-for-tutor';
export const GET_INVITATIONS_LIST_STUDENT_URL: string = 
   '/lesson-invitation/invitations-for-student';

export const GET_INVITATION_BY_ID_URL: string =
   '/lesson-invitation/invitation?invitationId=';
export const ADD_INVITATION_URL: string =
   '/lesson-invitation/create-invitation';
export const EDIT_INVITATION_URL: string =
   '/lesson-invitation/update-invitation';
export const DELETE_INVITATION_URL: string =
   '/lesson-invitation/delete-invitation';
export const START_LESSON_URL: string =
   '/lesson-invitation/start-lesson';   

export const GET_USERS_URL: string =
   '/user-management/users-list';   
   
export const EMAIL_VERIFICATION_URL: string =
   '/registration/email-verification/';   

export const CONTACT_US_URL: string =
   '/registration/contact-us/';   

export const WHITEBOARD_URL: string =
   '/lesson-invitation/start-whiteboard/';   

   

  

