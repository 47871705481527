import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../login/login.component';
import { ApiResponse } from '../model/apiresponse';
import { DeleteConfirmationParam } from '../model/delete-confirmation-param';
import { RestApiService } from '../service/restapi.service';
import { UIUtil } from '../util/ui-util';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.css'],
})
export class DeleteConfirmationDialogComponent implements OnInit {
  public deleteConfirmationParam: DeleteConfirmationParam;

  errorMessage:string;

  constructor(
    private restApi: RestApiService,
    private uiUtil: UIUtil,
    private modalRef: BsModalRef
  ) {}
  ngOnInit(): void {}

  handleConfirmButtonClick(): void {
    this.restApi.deleteItem(
      this.deleteConfirmationParam.deleteServiceUrl,
      null,
      (result: ApiResponse) => {
        this.modalRef.content = {
          closeEvent: 'deleted',
        };
        this.modalRef.hide();
        this.uiUtil.showSuccessMessage('Deleted successfully');
      },
      (error: ApiResponse) => {
        this.errorMessage = error.messageList[0].message;
      }
    );
  }

  handleCanceButtonClick(): void {
    this.modalRef.hide();
  }
}
