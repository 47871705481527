<app-header [currentPage]="'tutor-page'"></app-header>

<section id="features" class=" section" style="background:#333333;">
  <div class="video-wrapper">
    <video #landingVideo playsinline autoplay muted loop style="width: 100%;"
      oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/fireball.m4v" type="video/mp4">
    </video>
    <div class="video-description container">
      <img class="img-responsive" src="assets/images/feature-logo.png" style="width: 100%;">
    </div>
    <div class="video-description container">
      <img class="img-responsive arrow-img" src="assets/images/arrows.png" (click)="scroll(contentDiv)">
    </div>
  </div>
  <div class="container" #contentDiv>
    <div class="row mt-4">
      <div class="col-md-12 mt-4">
        <b style="font-size: 17px;">
          <span style="color: #fc7f38; font-size: 16px;">4K VIDEO QUALITY. </span> Meteor Video supports up to 4K Video Streaming if your internet connection can support it. On slower connections, Fireball will automatically offer High Definition, Standard Definition or Low Definition to ensure your meeting can continue uninterrupted.
        </b>
      </div>
      <div class="col-md-12 mt-4">
        <b style="font-size: 17px;">
          <span style="color: #fc7f38; font-size: 16px;">HI-FI AUDIO QUALITY. </span> Meteor Video supports Hi-Fi Audio Quality where your system and internet connection can support it. Even on a normal laptop setup, Fireball audio quality is superior to other video meeting systems.
        </b>
      </div>
      <div class="col-md-12 mt-4">
        <b style="font-size: 17px;">
          <span style="color: #fc7f38; font-size: 16px;">END-TO-END ENCRYPTION. </span> Meteor Video utilises the latest End-to-End Encryption technology to ensure that only registered users can join your meetings with full Two Factor Authentication (2FA). Fireball employs the most up-to-date standards to ensure your security and privacy.
        </b>
      </div>
      <div class="col-md-12 mt-4">
        <div class="col-md-2">
          <div id="google_translate_element"></div>
        </div>
        <div class="col-md-10">
          <b style="font-size: 17px;">
            <span style="color: #fc7f38; font-size: 16px;">GLOBAL LANGUAGES. </span> Although Meteor Video is very easy to use, for the start-up version we’ve used Google Translate to offer a few additional main languages used by our HOSTS and GUESTS to operate the site. Initially (in alphabetical order), there’s Chinese (Simplified), Chinese (Traditional), French, German, Italian, Japanese, Russian and Spanish. If any registered HOSTS or GUESTS feel they require other languages TO BE ADDED, just let us know via the Contact Page.
            </b>
        </div>
      </div>
      <div class="col-md-12 mt-4">
        <b style="font-size: 17px;">
          <span style="color: #fc7f38; font-size: 16px;">GROUP MEETINGS. </span> Meteor Video has been created to offer HOSTS the ability to LEAD MEETINGS of up to 100 GUESTS at a time, with the same class-leading standards of Video Quality, Sound Quality and End-to-End Encryption for your safety. Meteor Video supports up to 4K Video Streaming if your internet connection can support it.
        </b>
      </div>
      <div class="col-md-12 mt-4">
        <b style="font-size: 17px;">
          <span style="color: #fc7f38; font-size: 16px;">HOST SUBSCRIPTION. </span> Host subscription is £4.99 per month and Meetings last one hour, cancel at the end of any monthly billing period.
        </b>
      </div>
    </div>
    <!--//row-->


    

  </div>
  <!--//container-->
</section>
<!--//features-->

<app-footer></app-footer>