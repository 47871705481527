import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../service/restapi.service';
import { UIUtil } from '../util/ui-util';
import { CONTACT_US_URL } from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  public contactRequest:any={};
  public errorMessageMap = new Map<string, string>();

  constructor(
    private restApi: RestApiService,
    private uiUtil: UIUtil
  ) { }

  ngOnInit(): void {
  }

  handleSubmitButtonClick(): void {
    console.log(this.contactRequest);
    this.errorMessageMap = new Map<string, string>();
    this.restApi.postItem(
      CONTACT_US_URL,
      JSON.stringify(this.contactRequest),
      (response: ApiResponse) => {
        this.uiUtil.showSuccessMessage('Message sent!');
        this.contactRequest = {};
      },
      (error: ApiResponse) => {
        this.errorMessageMap = this.uiUtil.covertMsgListToMap(
          error.messageList
        );
        console.log(error);
      }
    );
  }

}
