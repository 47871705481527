<app-header [currentPage]="'error-page'"></app-header>

<section id="features" class=" section offset-header" style="background: #232323;">
    <div class="container">
        <div class="row">
            <div class="item col-lg-12 col-md-12 col-12 mt-5" style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
                <div class="" style="padding: 20px 50px;">
                        <h5>Internal Server Error <a href="/login">Click Here</a> to login</h5>
                    
                </div><!--//content-->               
            </div><!--//item-->
        </div><!--//row-->
        
        
        
    </div><!--//container-->
</section><!--//features-->

<app-footer></app-footer>
