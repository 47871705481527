<!-- Lesson started -->
<div id="videoDiv" style="height:100vh;">

</div>

<div style="display:none;" *ngIf="lessonStatus == 'STARTED'">
    <div simpleCountdown class="countdown" [dateTo]="lessonEndTime/1000" (finish)="timerFinished()"></div>
</div>

<div class="controllers" *ngIf="lessonStatus == 'STARTED'">

    <audio id="metroNomeAudioElem">
        <source src="assets/audio/metronome.mp3" type="audio/ogg">
      </audio>
    
    <button (click)="endCall();" class="controllerBtn">End</button>
    <button (click)="executeCommand('toggleShareScreen')" class="controllerBtn">Screen Share</button>
    <button (click)="toggleCamera();" class="controllerBtn">Change Camera</button>
    <button (click)="executeCommand('toggleAudio')" class="controllerBtn" *ngIf="!isAudioMuted">Mute Audio</button>
    <button (click)="executeCommand('toggleAudio')" class="controllerBtn" *ngIf="isAudioMuted">UnMute Audio</button>
    <button (click)="openWhieBoard()" class="controllerBtn">White Board</button>
    <button class="controllerBtn dropdown-toggle" [matMenuTriggerFor]="menu">Functions</button>
    <mat-menu #menu="matMenu" class="controllerBtn mat-menu">
        <button mat-menu-item class="mat-mdc-menu-item-text" (click)="toggleMetronome()">Metronome</button>
        <button mat-menu-item class="mat-mdc-menu-item-text" (click)="toggleCalculator()">Calculator</button>
        <button mat-menu-item class="mat-mdc-menu-item-text" (click)="toggleDictionary()">Dictionary</button>
        <button mat-menu-item class="mat-mdc-menu-item-text" (click)="togglePeriodicTable()">PeriodicTable</button>
    </mat-menu>
    <button *ngif="hideVirtualBackgroundBtn == false" (click)="toggleVirtualBackground()" class="controllerBtn">Virtual
        Background</button>
</div>

<!-- Lesson started -->

<!-- Yet To Start -->

<div *ngIf="lessonStatus == 'YET_TO_START'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd">Session Starts in </h1>
        <div simpleCountdown class="countdown" [dateTo]="lessonStartTime/1000" (finish)="timerFinished()"
            [endMessage]="'Session loading....'" [styles]="'font-size: 40px;
        color: #fc7f38;
        background-color: #272727;
        padding: 10px 4px;
        font-weight: bold;'"></div>
    </div>
</div>

<!-- Yet To Start -->

<!-- Expired -->

<div *ngIf="lessonStatus == 'EXPIRED'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd;">Session Expired</h1>
    </div>
</div>

<!-- Invalid Lesson -->

<div *ngIf="lessonStatus == 'INVALID'" class="status-text-style">
    <div class="centerd">
        <h1 style="color:#47defd;">Invalid Lesson</h1>
    </div>
</div>

<div *ngIf="isMetronome" class="meteornomebox-ctrl">
    <span style="position: absolute;right: 20px; cursor: pointer;z-index: 11;" (click)="toggleMetronome();">x</span>
    <div class="col-md-12">
        <div><span id="selectedBPM">{{selectedBPM}}</span></div>
        <div style="float:left;">
            <label class="">BPM</label>
        </div>
        <div>
            <input type="range" class="form-range" id="bpmRange" (input)="updateBpmDisplay($event)" [value]="selectedBPM" min="40"
                max="300">
        </div>

    </div>
    <div class="col-md-12">
        <div style="float:left; width:35px;">
            <label class="form-label" style="margin-bottom: -5px;">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="volume_up"></mat-icon>
            </label>
        </div>
        <div>
            <input type="range" class="form-range" id="volumeRange" [value]="50" min="0" max="100" (input)="changeValume($event)">
        </div>
    </div>
    <div class="col-md-12">
        <div>
            <mat-icon aria-hidden="false" aria-label="Example home icon" (click)="playMetronome()" *ngIf="!isPlaying" fontIcon="play_arrow"></mat-icon>
            <mat-icon aria-hidden="false" aria-label="Example home icon" (click)="pauseMetronome()" *ngIf="isPlaying" fontIcon="pause"></mat-icon>
        </div>
    </div>
</div>

<div *ngIf="isCalculator" class="calculator" cdkDrag>
    <span style="position: absolute;right: 20px; cursor: pointer;z-index: 11; top:10px;"
        (click)="toggleCalculator();">x</span>
    <span style="position: absolute;right: 30px; cursor: pointer;z-index: 11; top:12px;" id="calculatorheader" cdkDragHandle>
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="open_with" style="font-size: 15px;">open_with</mat-icon></span>
    <iframe src="https://calculator.grigiolondon.com" title="Calculator"
        style="width:100%; height:100%;border-radius: 15px; border-width: 0;"></iframe>
</div>

<div *ngIf="isPeriodicTable" class="periodicTable" cdkDrag>
    <span style="position: absolute;right: 20px; cursor: pointer;z-index: 11; top:10px;"
        (click)="togglePeriodicTable();">x</span>
    <span style="position: absolute;right: 30px; cursor: pointer;z-index: 11; top:12px;" id="periodTableHeader" cdkDragHandle>
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="open_with" style="font-size: 15px;">open_with</mat-icon></span>
    <iframe src="https://meteor-periodic-table.onrender.com" title="Calculator"
        style="width:100%; height:100%;border-radius: 15px; border-width: 0;"></iframe>
</div>

<div *ngIf="isDictionary" class="dictionary" cdkDrag>
    <span style="position: absolute;right: 20px; cursor: pointer;z-index: 11; top:10px;"
        (click)="toggleDictionary();">x</span>
    <span style="position: absolute;right: 30px; cursor: pointer;z-index: 11; top:15px;" id="dictionaryHeader" cdkDragHandle>
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="open_with" style="font-size: 15px;"></mat-icon></span>
    <!-- <iframe src="https://dictionary-ui.meteortutors.com/" title="Dictionary"
        style="width:100%; height:100%;border-radius: 15px; border-width: 0;"></iframe> -->
        <div style="width:100%; height:100%;border-radius: 15px; border-width: 0;">
            <app-lesson-dictionary></app-lesson-dictionary>
        </div>
</div>