import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { RestApiService } from '../service/restapi.service';
import { TutorInvitationMgtDialogComponent } from '../tutor-invitation-mgt-dialog/tutor-invitation-mgt-dialog.component';
import {
  DELETE_INVITATION_URL,
  GET_INVITATIONS_LIST_TUTOR_URL,
} from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { AbstractDataGridSupport } from '../base/AbstractDataGridSupport';
import { DataGridParams } from '../model/datagrid-model';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DeleteConfirmationParam } from '../model/delete-confirmation-param';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tutor-invitation-mgt',
  templateUrl: './tutor-invitation-mgt.component.html',
  styleUrls: ['./tutor-invitation-mgt.component.css'],
})
export class TutorInvitationMgtComponent
  extends AbstractDataGridSupport
  implements OnInit
{
  public invitations: any = [];

  constructor(
    private modalService: BsModalService,
    private restApi: RestApiService,
    private router: Router
  ) {
    super();
  }

  openTutorInvitationMgtDialog(
    mode: string,
    selectedInvitationId?: string
  ): void {
    var options: ModalOptions<TutorInvitationMgtDialogComponent> =
      new ModalOptions<TutorInvitationMgtDialogComponent>();
    //options.ignoreBackdropClick = true;
    options.class = 'modal-dialog-centered';
    options.initialState = {
      inputMode: mode,
      selectedInvitationId: selectedInvitationId,
    };

    var modelRef: BsModalRef = this.modalService.show(
      TutorInvitationMgtDialogComponent,
      options
    );

    modelRef.onHide.pipe().subscribe(() => {
      if (
        modelRef.content != null &&
        modelRef.content.closeEvent == 'submitted'
      ) {
        this.loadInvitationlist();
      }
    });
  }

  openDeleteConfirmationDialog(invitationId: number): void {
    var options: ModalOptions<DeleteConfirmationDialogComponent> =
      new ModalOptions<DeleteConfirmationDialogComponent>();
    let deleteParam:DeleteConfirmationParam = new DeleteConfirmationParam();
    deleteParam.dialogTitle = 'Delete Lesson Invitation';
    deleteParam.dialogMessage = 'Are you sure want to delete lesson invitation?';
    deleteParam.deleteServiceUrl = DELETE_INVITATION_URL+"?invitationId="+invitationId;
    //options.ignoreBackdropClick = true;
    options.class = 'modal-dialog-centered';
    options.initialState = {
      deleteConfirmationParam:deleteParam
    };

    var modelRef: BsModalRef = this.modalService.show(
      DeleteConfirmationDialogComponent,
      options
    );

    modelRef.onHide.pipe().subscribe(() => {
      if (
        modelRef.content != null &&
        modelRef.content.closeEvent == 'deleted'
      ) {
        this.loadInvitationlist();
      }
    });
  }

  getIDataGridParams(): DataGridParams {
    let dataGridParams: DataGridParams = {
      displayedColumns: [
        'lesson.title',
        'lesson.startTime',
        'lesson.lessonStatus',
        'actionButtonColumn',
      ],
      serverApi: () => {
        this.loadInvitationlist();
      },
    };
    return dataGridParams;
  }

  loadInvitationlist(): void {
    this.restApi.postItem(
      GET_INVITATIONS_LIST_TUTOR_URL,
      JSON.stringify(this.getDataGridRequest()),
      (response: ApiResponse) => {
        this.assignDataSourceParams(response.responseObject);
      }
    );
  }

  ngOnInit(): void {
    //this.initializeUIObjects();
  }

  initializeUIObjects() {
    this.loadInvitationlist();
  }

  openSessionPage(invitationId: number){
    this.router.navigate([]).then(result => {  window.open('/lesson?lessonInvitationId='+invitationId, '_blank'); });
  }
}
