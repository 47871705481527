import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  CREATE_STRIPE_PAYMENT_CONFIRMATION_URL,
  CREATE_STRIPE_PAYMENT_INTENT_URL,
} from '../constants/ApiURLConst';
import { ApiResponse } from '../model/apiresponse';
import { RestApiService } from '../service/restapi.service';
import { UIUtil } from '../util/ui-util';

declare var Stripe; // stripe.StripeStatic;

@Component({
  selector: 'app-stripe-payment-dialog',
  templateUrl: './stripe-payment-dialog.component.html',
  styleUrls: ['./stripe-payment-dialog.component.css'],
})
export class StripePaymentDialogComponent implements OnInit {
  public paymentIntentRequest: any;
  public paymentIntentObj: any;

  public currencySymbol: string = '£'; //TODO
  public amount: number;

  @ViewChild('cardElement') cardElement: ElementRef;

  public stripe: any;
  public card: any;
  public cardErrors: any;
  public isPaid: boolean = false;

  loading = false;

  constructor(
    private restApi: RestApiService,
    private uiUtil: UIUtil,
    private bsModalRef: BsModalRef
  ) {
    this.bsModalRef.content = {
      isPaid: false,
    };
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (
      this.paymentIntentRequest == null ||
      this.paymentIntentRequest.serviceType == null ||
      this.paymentIntentRequest.planId == null
    ) {
      this.cardErrors =
        'StripePaymentRequest Object Error !' +
        JSON.stringify(this.paymentIntentRequest);
      this.uiUtil.showErrorMessage(this.cardErrors);
      return;
    }
    this.loadStripePaymentIntent();
  }

  loadStripePaymentIntent(): void {
    this.restApi.postItem(
      CREATE_STRIPE_PAYMENT_INTENT_URL,
      JSON.stringify(this.paymentIntentRequest),
      (response: ApiResponse) => {
        this.paymentIntentObj = response.responseObject;
        this.amount = this.paymentIntentObj.paymentIntent.amount;
        this.createStripePaymentUI();
      }
    );
  }

  createStripePaymentUI(): void {
    this.stripe = Stripe(this.paymentIntentObj.publicKey);
    const elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
      },
    };
    this.card = elements.create('card', style);
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  makePayment(): void {
    this.stripe
      .confirmCardPayment(this.paymentIntentObj.paymentIntent.clientSecret, {
        payment_method: {
          card: this.card,
        },
        setup_future_usage: 'off_session',
      })
      .then((result: any) => {
        if (result.error) {
          this.cardErrors = result.error.message;
        } else {
          this.confirmStripePayment(this.paymentIntentObj.paymentIntent);
        }
      });
  }

  confirmStripePayment(paymentIntent: any): void {
    var confirmPaymentRequest: any = {
      paymentIntentId: paymentIntent.paymentIntentId,
      serviceType: this.paymentIntentRequest.serviceType,
      planId: this.paymentIntentRequest.planId,
    };

    this.restApi.postItem(
      CREATE_STRIPE_PAYMENT_CONFIRMATION_URL,
      JSON.stringify(confirmPaymentRequest),
      (response: ApiResponse) => {
        this.uiUtil.showSuccessMessage(
          'Payment Succces, Transaction Id ' +
            response.responseObject.paymentIntentId
        );
        console.log(response);
        this.isPaid = true;
        this.bsModalRef.content.isPaid = true;
      },
      (errorResponse: ApiResponse) => {
        this.uiUtil.showErrorMessage(errorResponse.messageList[0].message);
      }
    );
  }
}
