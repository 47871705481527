import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  DATAGRID_PAGE_SIZE_OPTIONS,
  DATAGRID_DEFAULT_PAGE_SIZE,
} from '../constants/AppConst';
import { ApiResponse } from '../model/apiresponse';
import { DataGridParams, DataGridRequest, DataGridResponse } from '../model/datagrid-model';

@Component({
  template: '',
})
export abstract class AbstractDataGridSupport implements AfterViewInit {
  private dataGridParams: DataGridParams;
  public dataSource: MatTableDataSource<any>;
  public totalRowsCount: number;
  public displayedColumns: string[];
  public pageSizeOptions: number[] = DATAGRID_PAGE_SIZE_OPTIONS;
  public defaultPageSize: number = DATAGRID_DEFAULT_PAGE_SIZE;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() {
    this.dataGridParams = this.getIDataGridParams();
    this.displayedColumns = this.dataGridParams.displayedColumns;
    this.dataSource = new MatTableDataSource();
  }
  abstract getIDataGridParams(): DataGridParams;

  ngAfterViewInit() {
   if (this.dataGridParams.initServerApi != null) {
      this.dataGridParams.initServerApi();
    } else {
      this.dataGridParams.serverApi();
    }
  }

  onSortData(event: any) {
    this.dataGridParams.serverApi();
  }

  onPaginateChange(event: PageEvent) {
    this.dataGridParams.serverApi();
  }

  getDataGridRequest(): DataGridRequest {
    return new DataGridRequest(
      this.paginator,
      this.sort,
      this.getAndFilterParams()
    );
  }

  //Note: Actual implementaion can be added in the derived class
  getAndFilterParams(): Object {
    return null;
  }

  assignDataSourceParams(response: DataGridResponse) {
    this.dataSource.data = response.dataList;
    this.totalRowsCount = response.totalRowsCount;
  }
}
