<app-header [currentPage]="'registration-success'"></app-header>


<section id="features" class=" section offset-header" style="background: #232323;">
    <div class="container">
        <h3 class="title  "><b>Registration Success</b></h3>
          
        <div class="row">
            <div class="item col-lg-12 col-md-12 col-12 mt-5" style="background: #46dffc;border-radius: 20px;margin-bottom: 20px;">
                <div class="" style="padding: 20px 50px;">
                        <h5>Check your email to complete registration</h5>
                    
                </div><!--//content-->               
            </div><!--//item-->
        </div><!--//row-->
        
        
        
    </div><!--//container-->
</section><!--//features-->





<app-footer></app-footer>


